<template>
	<div>
		<ZyroImageSelector
			:value="currentImage"
			@input="currentImage = $event"
		/>
	</div>
</template>

<script>
import {
	mapState,
	mapActions,
	mapGetters,
} from 'vuex';

export default {
	computed: {
		...mapState('gui', ['activeModalSettings']),
		...mapGetters('pages', ['blogPages']),
		currentImage: {
			get() {
				return this.blogPages[this.activeModalSettings.blogPostId].customData.coverImage.src;
			},
			set(newValue) {
				this.setPageData({
					type: 'blog',
					payload: {
						pageId: this.activeModalSettings.blogPostId,
						data: {
							customData: {
								coverImage: {
									src: newValue.url,
									alt: newValue.alt,
								},
							},
						},
					},
				});
			},
		},
	},
	methods: mapActions('pages', ['setPageData']),
};
</script>
