<template>
	<div>
		<ZyroFieldText
			:value="title"
			:label="$t('builder.blog.blogPostSettings.postTitle')"
			data-qa="blog-postsettings-inputfield-posttitle"
			@input="title = $event.target.value"
		/>
		<ZyroFieldText
			:value="description"
			:label="$t('builder.blog.blogPostSettings.postDescription')"
			input-tag="textarea"
			data-qa="blog-postsettings-inputfield-postdescription"
			@input="description = $event.target.value"
		/>
		<ZyroFieldText
			:value="pageUrl"
			:label="$t('builder.blog.blogPostSettings.url')"
			:error="error && $t('builder.blog.blogPostSettings.urlError')"
			:message="message"
			data-qa="blog-postsettings-inputfield-posturl"
			@input="pageUrl = $event.target.value"
		/>
		<!-- Making this disabled until datePicker component in implemented -->
		<ZyroFieldText
			v-if="!isDraft"
			:value="$options.sliceTimeFromISODate(date)"
			:error="error && $t('builder.blog.blogPostSettings.datePublished')"
			disabled
			@input="date = $event.target.value"
		/>
		<div
			v-if="isDraft"
			class="publish-button"
		>
			<ZyroButton
				theme="outline"
				class="publish-button"
				data-qa="blog-postsettings-btn-publishpost"
				@click="toggleBlogPostVisibility(activeModalSettings.blogPostId)"
			>
				{{ $t('builder.blog.blogPostSettings.publishPost') }}
			</ZyroButton>
		</div>
	</div>
</template>

<script>
import {
	mapState,
	mapActions,
	mapGetters,
} from 'vuex';

import { sliceTimeFromISODate } from '@/utils/date';
import { getValidPagePath } from '@/utils/urlValidators';

export default {
	sliceTimeFromISODate,
	data() {
		return { error: false };
	},
	computed: {
		...mapState(['domain']),
		...mapState('gui', ['activeModalSettings']),
		...mapGetters('pages', [
			'blogPages',
			'isPageUrlUnique',
		]),
		...mapGetters(['siteUrl']),
		isDraft() {
			return this.blogPages[this.activeModalSettings.blogPostId].customData.hidden;
		},
		message() {
			return `${this.$t('builder.blog.blogPostSettings.postUrl')}: ${this.siteUrl}${this.pageUrl}`;
		},
		pageUrl: {
			get() {
				return this.blogPages[this.activeModalSettings.blogPostId].path;
			},
			set(newValue) {
				const { url } = getValidPagePath(newValue);

				this.error = url === this.pageUrl ? false : !this.isPageUrlUnique(url);
				this.$emit('update-error', this.error);
				this.setPageData({
					type: 'blog',
					payload: {
						pageId: this.activeModalSettings.blogPostId,
						data: { path: url },
					},
				});
			},
		},
		title: {
			get() {
				return this.blogPages[this.activeModalSettings.blogPostId].meta.title;
			},
			set(newValue) {
				this.setPageData({
					type: 'blog',
					payload: {
						pageId: this.activeModalSettings.blogPostId,
						data: { meta: { title: newValue } },
					},
				});
			},
		},
		description: {
			get() {
				return this.blogPages[this.activeModalSettings.blogPostId].meta.description;
			},
			set(newValue) {
				this.setPageData({
					type: 'blog',
					payload: {
						pageId: this.activeModalSettings.blogPostId,
						data: { meta: { description: newValue } },
					},
				});
			},
		},
		date: {
			get() {
				return this.blogPages[this.activeModalSettings.blogPostId].customData.date;
			},
			set(newValue) {
				this.setPageData({
					type: 'blog',
					payload: {
						pageId: this.activeModalSettings.blogPostId,
						data: { date: newValue },
					},
				});
			},
		},
	},
	methods: {
		...mapActions('blog', ['toggleBlogPostVisibility']),
		...mapActions('pages', ['setPageData']),
	},
};
</script>

<style lang="scss" scoped>
.publish-button {
	display: flex;
	justify-content: center;
	margin-top: 16px;
	margin-bottom: 8px;
}
</style>
