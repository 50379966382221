<template>
	<ZyroModal
		width="360px"
		height="auto"
		@close-modal="closeAndDiscardChanges"
	>
		<template #title>
			{{ $t('common.postSettings') }} <span class="z-subheading">{{ $t('common.draft') }}</span>
		</template>
		<ZyroTabs
			:tabs="$options.tabs"
			:current-tab="currentTab"
			@change="currentTab = $event"
		/>
		<Component
			:is="currentTab.component"
			@set-button-mode="error = $event"
		/>
		<template slot="footer">
			<ZyroButton @click="closeAndDiscardChanges">
				{{ $t('common.cancel') }}
			</ZyroButton>
			<ZyroButton
				theme="primary"
				:disabled="error"
				@click="save"
			>
				{{ $t('common.save') }}
			</ZyroButton>
		</template>
	</ZyroModal>
</template>

<script>
import {
	mapState,
	mapActions,
	mapGetters,
} from 'vuex';

import BlogPostSettingsTabCategories from '@/components/builder-controls/blog-post-settings/BlogPostSettingsTabCategories.vue';
import BlogPostSettingsTabCoverImage from '@/components/builder-controls/blog-post-settings/BlogPostSettingsTabCoverImage.vue';
import BlogPostSettingsTabGeneral from '@/components/builder-controls/blog-post-settings/BlogPostSettingsTabGeneral.vue';
import i18n from '@/i18n/setup';
import {
	mapActionsGui,
	CLOSE_MODAL,
} from '@/store/builder/gui';
import { cloneDeep } from '@/utils/object';

const tabs = [
	{
		id: 'general',
		title: i18n.t('common.general'),
		component: 'BlogPostSettingsTabGeneral',
	},
	{
		id: 'coverImage',
		title: i18n.t('builder.blog.blockBlogList.post.coverImage'),
		component: 'BlogPostSettingsTabCoverImage',
	},
	{
		id: 'categories',
		title: i18n.t('common.categories'),
		component: 'BlogPostSettingsTabCategories',
	},
];

export default {
	components: {
		BlogPostSettingsTabGeneral,
		BlogPostSettingsTabCoverImage,
		BlogPostSettingsTabCategories,
	},
	data() {
		return {
			currentTab: this.$options.tabs[0],
			postBeforeEdit: null,
			blogPostId: null,
			error: false,
		};
	},
	computed: {
		...mapState('gui', ['activeModalSettings']),
		...mapGetters('pages', ['blogPages']),

		isDraft() {
			return this.blogPages[this.activeModalSettings.blogPostId].customData.hidden;
		},
	},
	mounted() {
		this.blogPostId = this.activeModalSettings.blogPostId;
		this.postBeforeEdit = cloneDeep(this.blogPages[this.blogPostId]);
	},
	methods: {
		...mapActionsGui({ closeModal: CLOSE_MODAL }),
		...mapActions('pages', [
			'setPageData',
			'pushPageDataToHistory',
		]),

		closeAndDiscardChanges() {
			this.setPageData({
				type: 'blog',
				payload: {
					pageId: this.blogPostId,
					data: this.postBeforeEdit,
				},
			});
			this.closeModal();
		},

		save() {
			this.pushPageDataToHistory({
				type: 'blog',
				payload: {
					pageId: this.blogPostId,
					oldData: this.postBeforeEdit,
				},
			});
			this.closeModal();
		},
	},
	tabs,
};
</script>
