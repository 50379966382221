<template>
	<EditableItemsWithDropdown
		:editable-items="editableItems"
		:placeholder="$t('builder.blog.blogPostSettings.writeCategories')"
		:button-text="$t('builder.blog.blogPostSettings.addCategory')"
		:dropdown-items="categoriesNames"
		:validate-value="validator"
		:selected-items="selectedItems"
		@edit="handleCategoryEdit"
		@update-items="updateItems"
		@add="addCategory"
		@select="selectCategory"
		@deselect="deselectCategory"
	/>
</template>

<script>
import {
	mapState,
	mapActions,
	mapGetters,
} from 'vuex';

import EditableItemsWithDropdown from '@/components/reusable-components/editable-items-list/-partials/EditableItemsWithDropdown.vue';

export default {
	components: { EditableItemsWithDropdown },
	computed: {
		...mapState('gui', ['activeModalSettings']),
		...mapGetters('blog', [
			'postCategories',
			'postCategoriesNames',
			'categoriesNames',
			'categoryIdByName',
		]),
		selectedItems() {
			return this.postCategoriesNames(this.activeModalSettings.blogPostId);
		},
		editableItems() {
			return this.selectedItems.map((name) => ({ name }));
		},
	},
	methods: {
		...mapActions('blog', [
			'addPostCategory',
			'editCategory',
		]),
		...mapActions('pages', ['setPageData']),
		validator(categoryName) {
			if (!categoryName) {
				return {
					isValid: false,
					error: this.$t('validate.emptyValue'),
				};
			}

			if (this.categoriesNames.includes(categoryName)) {
				return {
					isValid: false,
					error: this.$t('builder.blog.blogPostSettings.error'),
				};
			}

			return {
				isValid: true,
				error: '',
			};
		},
		updateItems(newCategoryNames) {
			this.setPageData({
				type: 'blog',
				payload: {
					pageId: this.activeModalSettings.blogPostId,
					data: { customData: { categories: newCategoryNames.map(({ name }) => this.categoryIdByName(name)) } },
				},
			});
		},
		addCategory(newItem) {
			this.addPostCategory({
				name: newItem,
				postId: this.activeModalSettings.blogPostId,
			});
		},
		handleCategoryEdit({
			oldItem,
			newItem,
		}) {
			this.editCategory({
				oldName: oldItem.name,
				newName: newItem.name,
			});
		},
		selectCategory(categoryName) {
			this.setPageData({
				type: 'blog',
				payload: {
					pageId: this.activeModalSettings.blogPostId,
					data: {
						customData: {
							categories: [
								...this.postCategories(this.activeModalSettings.blogPostId),
								this.categoryIdByName(categoryName),
							],
						},
					},
				},
			});
		},
		deselectCategory(categoryName) {
			const categoryToRemoveId = this.categoryIdByName(categoryName);

			this.setPageData({
				type: 'blog',
				payload: {
					pageId: this.activeModalSettings.blogPostId,
					data: {
						customData: {
							categories: [
								...this.postCategories(this.activeModalSettings.blogPostId)
									.filter((categoryId) => categoryId !== categoryToRemoveId),
							],
						},
					},
				},
			});
		},
	},
};
</script>
